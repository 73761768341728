header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*============ Moving Letters Animation  ==============*/
.letters-w {
    position: relative;
    display: inline-block;
    height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    align-items: center;
}
.f-letters-w {
    position: relative;
    display: flex;
    height: 100%;
    color: var(--color-light);
    text-align: center;
    align-items: center;
}
.f-letters-w .letterh{
    animation-delay: 0s;
}
.f-letters-w  .lettere{
    animation-delay: 0.25s;
}
.f-letters-w .letterl{
    animation-delay: 0.5s;
}
.f-letters-w  .letterll{
    animation-delay: 0.75s;
}
.f-letters-w .lettero{
    animation-delay: 1s;
}
.f-letters-w .letteri{
    animation-delay: 1.25s;
}
.f-letters-w .lettera{
    animation-delay: 1.5s;
}
.f-letters-w .letterm{
    animation-delay: 1.75s;
}

.letterh, .lettere, .letterl, .letterll, .lettero, .letterI, .lettera, .letterm {
    text-align: center;
    position: relative;
    display: block;
    height: 100%;
    top: 60%;
    margin-left: 0;
    line-height: 100px;
    font-family: "poppins", sans-serif;
    font-size: 32px;
    -webkit-text-stroke: 1px var(--color-light);
    opacity: 0;
    animation: moveLetter 0.5s ease-out forwards;
}

@keyframes moveLetter 
{
    0% {
        top: 60%;
        opacity: 0;
    }
    70% {
        top: -30%;
        opacity: 0.7;
    }
    100% {
        top: 0;
        opacity: 1;
    }
}

/*========== Name Animation  =========*/

.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.static-txts h3{
    margin-bottom: 2rem;
    text-align: center;
}
.wrapper .static-txts{
  color: var(--color-white);
  font-size: 2rem;
  font-weight: 600;
  justify-content: center;
  text-align: center;
}
.wrapper .dynamic-txts{
  margin-left: 15px;
  height: 90px;
  line-height: 90px;
  overflow: hidden;
}
.dynamic-txts li{
  list-style: none;
  color: var(--color-primary);
  font-size: 60px;
  font-weight: 500;
  position: relative;
  top: 0;
  animation: slide 12s steps(4) infinite;
}
@keyframes slide {
  100%{
    top: -360px;
  }
}
.dynamic-txts li span{
  position: relative;
  margin: 5px 0;
  line-height: 90px;
}
.dynamic-txts li span::after{
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--color-bg);
  border-left: 2px solid var(--color-primary);
  animation: typing 3s steps(10) infinite;
}
@keyframes typing {
  40%, 60%{
    left: calc(100% + 30px);
  }
  100%{
    left: 0;
  }
}



/*  ==========  CTA  ==========*/
.cta {
    margin-top:  2.5rem;
    display: flex;
    gap: 1.2rem ;
    justify-content: center;
}

/*  ==========  Header Socials  ==========*/

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after{ /*pseudoelement for the line*/
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


/*  ==========  ME  ==========*/
.me {
    background: linear-gradient(var(--color-primary),transparent);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0 ;
    overflow: hidden;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
 
    
}

/*  ==========  Scroll Down  ==========*/
.scroll__down{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);

}

/*  ==========  Media Query for Medium Devices  ==========*/

@media  screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }

    
 }

 /*  ==========  Media Query for Small Devices  ==========*/

 @media  screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
    .wrapper .dynamic-txts{
        margin-left: 6px;
        height: 75px;
        line-height: 75px;      
    }

      .dynamic-txts li{
        font-size: 30px;
        font-weight: 600;
      }
      @keyframes slide {
        100%{
          top: -360px;
        }
      }
      .dynamic-txts li span{
        position: relative;
        margin: 5px 0;
        line-height: 90px;
      }
      .dynamic-txts li span::after{
        content: "";
        position: absolute;
        left: 0;
        margin-top: 25px;
        height: 100%;
        width: 100%;
        background: var(--color-bg);
        border-left: 2px solid var(--color-primary);
        animation: typing 3s steps(10) infinite;
      }
 
 }







